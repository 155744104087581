<template>
    <div class="pay" id="agentTitle">
        <ExistingOrderTips>
            <template slot-scope="slotProps" slot="big-title-text">亲爱的{{  orderinfo.xname }}宝宝</template>
            <template v-slot:small-title-text><div class="ExistingOrderTips_text_size">您专属起名报告已生成</div></template>
        </ExistingOrderTips>
        <!-- <div class="header center" style="position:fixed;top:0;z-index:100">诸葛国学起名网</div> -->
        <Imgbox v-if="showCopyright" class="topheader" src="http://cdn1.zhiyileiju.cn/WechatIMG345.jpeg" />
        <Card style="margin-top:-5px" class="paybox img">
            <div class="info">
                <p class="usertip" style="font-weight:bold">宝宝身份</p>
                <van-row class="userinforow">
                    <van-col span="12">
                        <span>姓氏：</span>
                        <span>{{ orderinfo.xname }}</span>
                    </van-col>
                    <van-col span="12">
                        <span>性别：</span>
                        <span>{{ orderinfo.sex }}</span>
                    </van-col>
                </van-row>
                <van-row class="userinforow">
                    <van-col span="24">
                        <span>出生日期（公历）：{{ orderinfo.bDate[0] }}年{{
                            orderinfo.bDate[1]
                            }}月{{ orderinfo.bDate[2] }}日{{ orderinfo.bDate[3] }}时</span>
                    </van-col>
                </van-row>
            </div>
            <p class="paytip center">(为您节省学习起名知识所需的大量时间与精力)</p>
            <p class="paytip center h1">200套精选吉祥美名</p>
            <div class="center">
                <div class="priceinfo">
                    <div class="paytime">
                        限时优惠：
                        <van-count-down millisecond color="#fd0101" :time="time" format="HH:mm:ss:SS" />
                    </div>
                    <div class="realpricebox">
                        <span class="realprice">￥{{ price.true_amount }}</span>
                        <span class="discountprice">原价: ￥{{ price.fake_amount }}</span>
                    </div>
                    <div class="pricefooter center">买一赠一，共可看200个美名</div>
                </div>
            </div>
            <p class="intip center">好名字成就宝宝一生幸福，帮宝宝起好名</p>
            <van-button type="primary" size="large" color="#04C967" style="margin-bottom: 14px;font-size:22px" icon="http://cdn1.zhiyileiju.cn/wechaticon111.png" round block @click="getpayurl('wechat','v1_pay_wechat')">微信支付</van-button>
            <van-button type="primary" size="large" color="#1B95D4" v-if="!is_weixn" style="font-size:22px" icon="http://cdn1.zhiyileiju.cn/alipayicon111.png" round block @click="getpayurl('alipay','v1_pay_alipay')">支付宝</van-button>
            <p class="intip center">
                已有<span style="color: #fd0101;font-weight:bold">9,316,618</span>个人支付,获得了美名
            </p>
            <img class="center" style="width: 100%" src="https://img.nnabc.cn//baozhang.png" alt="" />
            <img class="center" style="width: 100%; margin-top: 15px" src="http://cdn1.zhiyileiju.cn/paysdfdsf.jpg" alt="" />
        </Card>
        <h2 class="center tiph2">支付后你将看到以下所有信息</h2>
        <!-- <Card v-if="!isks4" class="img userCard">
            <div class="userBox">
                <div class="xingshi">{{ orderinfo.xname }}</div>
                <div class="userJBXX">
                    <p>性别：{{ orderinfo.sex }}</p>
                    <p>
                        农历/阴历：{{ orderinfo.nongli.gzYear }}年{{
                        orderinfo.nongli.monthCn + orderinfo.nongli.dayCn
                        }}
                    </p>
                </div>
            </div>
            <table class="table" border="0" cellspacing="0">
                <tbody>
                    <tr>
                        <th style="width: 24%">示例</th>
                        <th style="width: 19%">年柱</th>
                        <th style="width: 19%">月柱</th>
                        <th style="width: 19%">日柱</th>
                        <th style="width: 19%">时柱</th>
                    </tr>
                    <tr v-if="showCopyright">
                        <td>出生时间</td>
                        <td k="year_zhu">{{ orderinfo.eightChara.bazi[0].y }}</td>
                        <td k="month_zhu">{{ orderinfo.eightChara.bazi[1].m }}</td>
                        <td k="day_zhu">{{ orderinfo.eightChara.bazi[2].d }}</td>
                        <td k="hour_zhu">{{ orderinfo.eightChara.bazi[3].h }}</td>
                    </tr>
                    <tr>
                        <td>纳音</td>
                        <td k="year_ly">{{ orderinfo.eightChara.nayin[0].y }}</td>
                        <td k="month_ly">{{ orderinfo.eightChara.nayin[1].m }}</td>
                        <td k="day_ly">{{ orderinfo.eightChara.nayin[2].d }}</td>
                        <td k="hour_ly">{{ orderinfo.eightChara.nayin[3].h }}</td>
                    </tr>
                    <tr>
                        <td>喜用分析</td>
                        <td colspan="4">
                            <div class="pifen disflex" wx="木">
                                <span>木</span>
                                <div class="progress">
                                    <span class="back_jin" :style="'width:' + orderinfo.askTao.qk_score_ratio['木']"></span>
                                </div>
                                <span class="fs">{{
                                    orderinfo.askTao.qk_score_ratio["木"]
                                    }}</span>
                            </div>
                            <div class="pifen disflex" wx="火">
                                <span>火</span>
                                <div class="progress">
                                    <span class="back_mu" :style="'width:' + orderinfo.askTao.qk_score_ratio['火']"></span>
                                </div>
                                <span class="fs">{{ orderinfo.askTao.qk_score_ratio["火"] }}
                                </span>
                            </div>
                            <div class="pifen disflex" wx="土">
                                <span>土</span>
                                <div class="progress">
                                    <span class="back_shui" :style="'width:' + orderinfo.askTao.qk_score_ratio['土']"></span>
                                </div>
                                <span class="fs">{{
                                    orderinfo.askTao.qk_score_ratio["土"]
                                    }}</span>
                            </div>
                            <div class="pifen disflex" wx="金">
                                <span>金</span>
                                <div class="progress">
                                    <span class="back_huo" :style="'width:' + orderinfo.askTao.qk_score_ratio['金']"></span>
                                </div>
                                <span class="fs">{{
                                    orderinfo.askTao.qk_score_ratio["金"]
                                    }}</span>
                            </div>
                            <div class="pifen disflex" wx="水">
                                <span>水</span>
                                <div class="progress">
                                    <span class="back_tu" :style="'width:' + orderinfo.askTao.qk_score_ratio['金']"></span>
                                </div>
                                <span class="fs">{{
                                    orderinfo.askTao.qk_score_ratio["水"]
                                    }}</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </Card> -->
        <Card class="img userCard">
            <div class="title2 disflex">200套精选吉祥好名</div>
            <div class="infoImg">
                <div class="info">
                    <div class="infoText shadow">
                        <p>1、精选200套好听、好意、好记的吉祥美名！</p>
                        <p>2、名字注重吉祥如意、幸福安康，为宝宝注入成长能量!</p>
                    </div>
                    <van-button class="getbutton" size="large" color="#B95353" type="info" @click="pay('v1_pay_content_btn')">
                        <template #icon>
                            <img style="width:15px;margin-right:8px" src="http://cdn1.zhiyileiju.cn/lock%20close.png" alt="" />
                        </template>
                        立即解锁200套美名</van-button>
                </div>
            </div>
        </Card>
        <Card class="img userCard">
            <div class="title2 disflex">{{ orderinfo.xname }}姓宝宝 起名宜用字</div>
            <div class="infoImg">
                <div class="info">
                    <div class="infoText shadow">
                        <p>1、根据宝宝出生时间科学了解宝宝的名字适宜用哪些字！</p>
                        <p>
                            2、从五行喜忌中找出缺少的部分加以改善补齐，扬长避短，助运宝宝成长！
                        </p>
                    </div>
                    <van-button class="getbutton" size="large" color="#B95353" type="info" @click="pay('v1_pay_content_btn')">
                        <template #icon>
                            <img style="width:15px;margin-right:8px" src="http://cdn1.zhiyileiju.cn/lock%20close.png" alt="" />
                        </template>
                        立即解锁200套美名</van-button>
                </div>
            </div>
        </Card>
        <Card class="img userCard">
            <div class="title2 disflex">{{ orderinfo.xname }}姓宝宝 经典国学起名</div>
            <div class="infoImg">
                <div class="info">
                    <div class="infoText shadow">
                        <p>
                            1、出自楚辞诗经、唐诗宋词国学经典，精练好名，寓意好，上档次，重名概率低！
                        </p>
                        <p>2、不费功夫为宝宝取个高雅大气有内涵的好名字。</p>
                    </div>
                    <van-button class="getbutton" size="large" color="#B95353" type="info" native-type="submit" @click="pay('v1_pay_content_btn')">
                        <template #icon>
                            <img style="width:15px;margin-right:8px" src="http://cdn1.zhiyileiju.cn/lock%20close.png" alt="" />
                        </template>
                        立即解锁200套美名</van-button>
                </div>
            </div>
        </Card>
        <Card class="img userCard">
            <div class="title2 disflex">性格特点和先天潜力</div>
            <div class="infoImg">
                <div class="info">
                    <div class="infoText shadow">
                        <p>1、全面分析宝宝性格，合理安排，宝爸宝妈能少操心。</p>
                        <p>2、提前了解宝宝天赋、加以培养，让宝宝赢在起跑线上。</p>
                    </div>
                    <van-button class="getbutton" size="large" color="#B95353" type="info" native-type="submit" @click="pay('v1_pay_content_btn')">
                        <template #icon>
                            <img style="width:15px;margin-right:8px" src="http://cdn1.zhiyileiju.cn/lock%20close.png" alt="" />
                        </template>
                        立即解锁200套美名</van-button>
                </div>
            </div>
        </Card>
        <Card class="img">
            <Comments />
            <div class="title2 disflex">最新支付订单</div>
            <orderscroll />
            <div class="title2 disflex mt-12">常见问题</div>
            <van-collapse v-model="activeNames" :accordion="true">
                <van-collapse-item :is-link="false" title="你们是专业正规的起名公司吗？如何相信？" name="1">我们公司是经市场监管管理局核准，进行起名软件服务的专业平台！您有任何疑问，诸葛国学起名有专职的售后客服，及时为您解决任何问题。
                </van-collapse-item>
                <van-collapse-item :is-link="false" title="已付款成功，多久可以看到起名结果？ " name="2">已付款成功，等待1-5分钟即可看到起名结果。</van-collapse-item>
                <van-collapse-item :is-link="false" title="如何查看宝宝五行缺什么？" name="3">首先要谨记，五行不是缺什么就补什么，要看五行喜用什么。诸葛国学起名网的五行计算方式，是以五行含量计算的，不是按照五行个数计算的。五行含量可以保留到小数点后一位，五行个数只能保留到整数位。五行含量讲的是平衡，并且五行含量是以同类、异类成组对应看的，要让同类五行含量与异类五行含量保持平衡，最后选出实际的五行喜用。
                </van-collapse-item>
                <van-collapse-item :is-link="false" title="起名不满意怎么办？" name="4">诸葛国学起名的不满意率只有千分之二，原因大多是因为操作不熟练、需求不明确、追求满分造成的。
                    如果您有疑问请及时联系客服，我们一定能完美解决您所提出的问题，争取满意百分百。
                </van-collapse-item>
                <van-collapse-item :is-link="false" title="取名结果可以保存多久？" name="5">您在诸葛国学起名网支付的取名结果可以“永久保存”。</van-collapse-item>
            </van-collapse>
        </Card>
        <van-dialog v-model="showPayDialog" :showCancelButton="false" :showConfirmButton="false" :width="dialogwidth">
            <div class="diflex dialogbox">
                <div class="dialogheader center">
                    <span>支付信息</span>
                    <img @click="showPayDialog = false" class="closeicon" src="http://cdn1.zhiyileiju.cn/closeicon.png" alt="" />
                </div>
                <div class="content center">
                    <span style="margin-right: 20px">姓氏：{{ orderinfo.xname }} </span>
                    <span>姓别：{{ orderinfo.sex }} </span>
                </div>
                <div class="content center">
                    出生日期（公历）：{{ orderinfo.bDate[0] }}年{{
                    orderinfo.bDate[1]
                    }}月{{ orderinfo.bDate[2] }}日{{ orderinfo.bDate[3] }}时
                </div>
                <div class="content pricewarp">
                    <span class="dialogrealprice">原价：￥{{ price.fake_amount }}</span>
                    <span class="tehuiprice">特惠价:</span>
                    <span class="dialogdisp">￥{{ price.true_amount }}</span>
                </div>
                <div class="content center">
                    <span style="margin-right: 5px">距离特价结束</span>
                    <van-count-down millisecond color="#fd0101" :time="time" format="HH:mm:ss:SS" />
                </div>
                <div class="paybtnbox">
                    <van-button type="primary" color="#04C967" style="margin-bottom: 14px;width:calc(100% - 0px);font-size:22px" icon="http://cdn1.zhiyileiju.cn/wechaticon111.png" round block @click="getpayurl('wechat','v1_pay_dialog_wechat')">微信支付</van-button>
                    <van-button type="primary" color="#1B95D4" icon="http://cdn1.zhiyileiju.cn/alipayicon111.png" round style="width:calc(100% - 0px);font-size:22px" block @click="getpayurl('alipay','v1_pay_dialog_alipay')">支付宝</van-button>
                    <img class="center" style="width: 100%; margin-top: 15px" src="https://img.nnabc.cn/baozhang.png" alt="" />
                </div>
                <div class="content center green">
                    支付系统已经经过安全联盟认证请放心使用
                </div>
            </div>
        </van-dialog>
        <van-button type="primary" class="fixbtn" :class="fixedbtn == true ? 'isFixed' : ''" color="#B95353" block @click="pay('v1_pay_bottom_btn')">点击立即获取吉祥美名</van-button>
        <van-overlay :show="shopPayConfirm" z-index="10" lock-scroll>
            <div class="wrapper center" @click.stop>
                <!-- <div class="block">
                    <div class="title">请确认支付是否完成</div>
                    <div class="payed">
                        <van-button round block @click="Dcheckorder" class="Dcheckorder" type="primary">付款成功，点击查看名字</van-button>
                    </div>
                    <div class="unpay" @click="unpay">
                        未支付，我再考虑一下
                    </div>
                </div> -->
                <div class="block">
                    <div class="title">
                        <p class="top_title_">离宝宝好名报告只差1步</p>
                        <p class="bottom_title_" style="margin-top:-9px;">支付后查看200个美名</p>
                    </div>
                    <div class="bg_box_chunk_">
                            <img :src="buttom_top_src_img" alt="">
                    </div>
                    <div class="payed" style="margin-top: 11%;" @click="getpayurls('','')">
                        <img src="http://cdn1.zhiyileiju.cn/WechatIMG600.jpeg" alt="">
                    </div>
                    <div class="payed" @click="unpay">
                        <img :src="buttom_src_img"  alt="">

                    </div>
                    <div class="payed"  @click="Dcheckorder">
                        <img src="http://cdn1.zhiyileiju.cn/WechatIMG605.png" alt="">
                    </div>
                    <div class="countbox center">
                        <van-count-down :time="counttime" millisecond>
                            <template #default="timeData">
                                <span class="block_">{{ timeData.hours }}</span>
                                <span class="colon">:</span>
                                <span class="block_">{{ timeData.minutes }}</span>
                                <span class="colon">:</span>
                                <span class="block_">{{ timeData.seconds }}</span>
                                <span class="colon">:</span>
                                <span class="block_">{{ parseInt(timeData.milliseconds / 10) }}</span>
                            </template>	
                        </van-count-down>
                        <span style="color:#525252;font-size:12px;margin-left:10px;font-weight: 500;">后失效</span>
                    </div>
                </div>
            </div>
        </van-overlay>
        
       <div  v-if="isbjks"  class="webfooter" style="height:180px;font-size:10px;color:#323232">
			<div>版权所有 上海容铭信息科技有限公司</div>
			<div>公司地址：上海市杨浦区抚顺路360号</div>
			<div>电话：18975441215</div>
		</div>
		<div v-else-if="isks4"  class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>版权所有   武汉道森梯媒文化传播有限公司</div>
			<div>地址：武汉市武昌区徐东大街128号</div>
			<div>电话：18975441215</div>
		</div>
        
        <div v-else-if="sxhjzn"  class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>版权所有 绍兴恒健智能科技有限公司</div>
			<div v-if="sxhjznqdh">地址：浙江省绍兴市柯桥区齐贤街道屠家娄95号</div>
			<div v-if="sxhjznqdh">电话：13024279188</div>
		</div>
        <div v-else-if="sxhjkj"  class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>版权所有 绍兴炅嘉科技有限公司</div>
			<div v-if="sxhjznqdh">绍兴炅嘉科技有限公司</div>
			<div v-if="sxhjznqdh">电话：18157297473</div>
		</div>
        <div v-else-if="wcqxhqmg"  class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>版权信息 ：潍城区玄海起名馆</div>
		</div>
        <div v-else-if="cqlsshfw"  class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>版权信息 重庆乐善生活服务有限公司第三分公司</div>
		</div>

      

       	<div v-else-if="ifFqudao"  class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>版权所有   苏州特诺维信息技术有限公司</div>
			<div>地址：苏州工业园区星湖街328号创业产业园A1-16</div>
			<div>电话：15695165587</div>
		</div>
        <div v-else-if="nohnllwh" class="webfooter" style="padding-bottom:0; background:none; font-size:10px;color:#323232"></div>
		<!-- <div v-else  class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>版权所有   湖南亮亮文化传媒有限公司</div>
			<div>地址：长沙市开福区月湖街道鸭子铺路1号1房2室</div>
			<div>电话：18975441215</div>
		</div> -->

        <div v-if="!zxaaaa" class="webfooter" style="margin-top: -80px;font-size:10px;color:#323232">
			<!-- <a @click="ICPCallback"></div> -->
			<a href="https://beian.miit.gov.cn/" style="color:#d5612b"><span style="color:#323232"></span>{{put_records}}</a>
		</div>
        <div class="webfooter"  style="height:120px;background:#fdf9ea;"></div>、

       
    </div>
</template>
<script>
import Comments from "../components/Comment.vue";
import vueSeamlessScroll from "vue-seamless-scroll";
import orderscroll from "../components/OrderScroll.vue";
import axios from "../utils/request";
import solarLunar from "../components/datepick/solarlunar";
import ExistingOrderTips from "../components/ExistingOrderTips/index.vue"
import { CountDown } from "vant";

import { Overlay } from "vant";
import { Toast } from "vant";

import { Dialog } from "vant";

import router from "../router";

import report from "../utils/stat";



export default {
    name: "Pay",
    components: {
        Comments,
        vueSeamlessScroll,
        vanCountDown: CountDown,
        vanOverlay: Overlay,
        vanToast: Toast,
        orderscroll,
        ExistingOrderTips
    },
    async mounted() {
	    if (this.is_weixn) {
				let wxconfig = await this.getWxConfig();
				wx.config({
					debug: false,
					appId: wxconfig.appId,
					timestamp: wxconfig.timestamp,
					nonceStr: wxconfig.nonceStr,
					signature: wxconfig.signature,
					jsApiList: ['chooseWXPay']
				});
			}
	
	    document
	        .getElementById("agentTitle")
	        .scrollIntoView({ block: "start", behavior: "smooth" });
			document.title = "诸葛国学测名";

        document
            .getElementById("agentTitle")
            .scrollIntoView({ block: "start", behavior: "smooth" });
        window.addEventListener("scroll", this.handleScroll);

        sessionStorage.setItem('orderid',this.$route.params.id);

        report("v1_pay");
    },
    data() {
        return {
            buttom_src_img:'http://cdn1.zhiyileiju.cn/WechatIMG606.png',
            buttom_top_src_img:'http://cdn1.zhiyileiju.cn/WechatIMG602.jpeg',
            counttime:10*60*1000,
            listData: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            orderlist: [],
            activeNames: ["1"],
            showPayDialog: false,
            showfixPayBtn: true,
            shopPayConfirm: false,//支付后弹窗
            // shopPayConfirm: true,//支付后弹窗
            fixedbtn: false,
            orderinfo: {
                bDate: [2021],
                nongli: {},
                eightChara: {
                    bazi: [{ y: "辛丑" }, { m: "乙未" }, { d: "甲子" }, { h: "甲子" }],
                    nayin: [
                        { y: "壁上土" },
                        { m: "沙中金" },
                        { d: "海中金" },
                        { h: "海中金" },
                    ],
                },
                askTao: {
                    qk_score_ratio: {
                        金: "6%",
                        木: "11%",
                        水: "25%",
                        火: "12%",
                        土: "46%",
                    },
                },
            },
            time: 1000 * 60 * 30,
            price: {
                base_level: null,
                created_at: null,
                fake_amount: "198.00",
                fast_amount: null,
                id: 1,
                true_amount: "",
                type: 1,
                updated_at: null,
            },
            orderfakedata: [{
                    name: "王妈妈",
                    time: "12分钟前",
                },
                {
                    name: "李妈妈",
                    time: "7分钟前",
                },
                {
                    name: "孙爸爸",
                    time: "3分钟前",
                },
                {
                    name: "刘妈妈",
                    time: "5分钟前",
                },
                {
                    name: "赵阿姨",
                    time: "1分钟前",
                },
                {
                    name: "周妈妈",
                    time: "3分钟前",
                },
                {
                    name: "李阿姨",
                    time: "3分钟前",
                },
                {
                    name: "古妈妈",
                    time: "5分钟前",
                },
            ],
        };
    },
    destroyed() {
        this.timer && clearTimeout(this.timer);
    },
    async created() {
        if ( window.localStorage.getItem('qm_red_packet_type') === null) {
            this.buttom_src_img = 'http://cdn1.zhiyileiju.cn/WechatIMG606.png'
            this.buttom_top_src_img = 'http://cdn1.zhiyileiju.cn/WechatIMG602.jpeg'
        }else{
            this.buttom_src_img = 'http://cdn1.zhiyileiju.cn/WechatIMG606.png'
            this.buttom_top_src_img = 'http://cdn1.zhiyileiju.cn/WechatIMG608.jpeg'
        }
        let paytype = this.$route.params.type;
        this.orderid = this.$route.params.id;
        this.type = this.$route.params.type;
        if (paytype != 10) {
            try {
                await this.mcheckorder();
            } catch (err) {}
            this.shopPayConfirm = true;
        } else {}
        this.getorderinfo(this.$route.params.id);
        this.getFakeOrder();
        await this.getprice();
        this.showToast();
    },

    computed: {
        is_weixn() {
            var ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                return true;
            } else {
                return false;
            }
        },
        defaultOption() {
            return {
                step: 0.5, // 数值越大速度滚动越快
                limitMoveNum: this.listData.length,
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                openWatch: false, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
                openTouch: false,
            };
        },
        dialogwidth() {
            return window.innerWidth * 0.9;
        },
        showCopyright() {
            let from = this.$route.query.from;
            let allowfrom = ['a1','a2', 'a6', 'a7','ab1', 'b1','d1', 'a9','k1','k2','ad1','k3','ac1','f1'];
            if (allowfrom.indexOf(from) > -1) {
                return true
            } else {
                return false
            }
        },
        isbjks(){
            let from = this.$route.query.from;
            let allowfrom = ['k1', 'k2', 'k3', 'k4','r1','k31','k32','k35'];
            if (allowfrom.indexOf(from) > -1) {
                return true
            } else {
                return false
            }
        },
        ifFqudao(){
            let from = this.$route.query.from;
                let allowfrom = ['f1', 'f2'];
            if (allowfrom.indexOf(from) > -1) {
                return true
            } else {
                return false
            }
		},
        sxhjzn(){
            let from = this.$route.query.from;
                let allowfrom = ['bdqm1','bdqm3'];
            if (allowfrom.indexOf(from) > -1) {
                return true
            } else {
                return false
            }	
        },
        sxhjkj(){
				let from = this.$route.query.from;
					let allowfrom = ['bdqm4','bdqm5'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}	
			},
        sxhjznqdh(){ //去掉电话和地址
            let from = this.$route.query.from;
                let allowfrom = ['bdqm3'];
            if (allowfrom.indexOf(from) > -1) {
                return false
            } else {
                return true
            }	
        },
        wcqxhqmg() {//潍城区玄海起名馆
				let from = this.$route.query.from;
				let allowfrom = ['k38','k39','k41'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
       cqlsshfw(){
				let from = this.$route.query.from;
					let allowfrom = ['k40','k61'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
        nohnllwh() {
				let from = this.$route.query.from;
				let allowfrom = ['k31','k35','k50','k51','k52'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
        },
        zxaaaa() {
				let from = this.$route.query.from;
				let allowfrom = ['g1','r1','z1','bdqm4','k38','k39','k40','k41'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					let allowfrom1 = ['k31','k35','k50','k51','k52'];
					if (allowfrom1.indexOf(from) > -1) {
						this.put_records = "沪ICP备19000880号-2"
						return false
					} 
					// return false
				}
        },

        isks4(){
            let from = this.$route.query.from;
            let allowfrom = ['d1', 'd2', 'd3', 'd4','qs1','qs3'];
            if (allowfrom.indexOf(from) > -1) {
                return true
            } else {
                return false
            }
        },
    },
    methods: {
        pay(action) {
            this.showPayDialog = true;
            report(action);
        },
        async getWxConfig() {
				let url = window.location.href;

				let data = await axios.post("/payment/wechatconf", {
					url
				})
				return data.data.data.data;
			},
        unpay(){
            report("v1_pay_account_paid");
            // router.back();
            this.$router.replace('/index')
        },
        async getFakeOrder() {
            let info = await axios.post("/ekaf", {
                type: 2,
                perPage: 15,
                page: 1,
                fromk: this.$route.query.from
            });
            this.orderlist = info.data.data;
        },

        showToast() {
            let t = this;
            let arr = this.orderfakedata;
            let data = arr[Math.floor(Math.random() * arr.length)];
            Toast({
                message: `${data.time} ${data.name}支付了${this.price.true_amount}元国学起名`,
                position: "bottom",
                duration: 2000,
            });
            this.timer = setTimeout(function() {
                t.showToast();
            }, 5000);
        },

        async timeout(time) {
            return new Promise((sol, ret) => {
                setTimeout(() => {
                    sol()
                }, time);
            })
        },

        async getpayurl(payway, aciton) {
             if ( payway === '') {
                payway = this.$route.params.type === '1' ? 'alipay' : 'wechat'
             }else{
                report(aciton);
             }
            Toast.loading({
                message: '请稍后...',
                forbidClick: true,
            });
            
            if ( this.is_weixn ) {
                setInterval( ()=> {this.checkorder(orderid, 2);}, 2000)
                
            }

            let orderid = this.orderid;
            let type = payway == "alipay" ? 1 : 2;
            this.type = type;
            let orderinfo = await axios.get("/order/" + orderid);
            if (
                orderinfo.data.data.order_status == "已完成" ||
                orderinfo.data.data.order_status == "已支付"
            ) {
                Dialog.confirm({
                    message: "当前订单已支付",
                    showCancelButton: false,
                }).then(() => {
                    sessionStorage.setItem('paysuccess','1');
                    router.replace({ name: "paysuccess", params: { id: orderid, type } }).catch(() => {});
                });
                return;
            }
            let t = this;
            setTimeout(async function() {
                await t.checkorder(orderid, type);
            }, 2000);
            try {
                let confirm_order_data_ = {
                    order_id: orderid,
                    paytype: type,
                }
                await this.confirmOrder(confirm_order_data_);
                Toast.clear()
                
                this.shopPayConfirm = true;
                if (this.is_weixn) {
                    let payinfo = await axios.get(
                        `https://api.zhiyileiju.cn/api/v1/payment/${payway}/${orderid}/wechat`)
                    let pay = payinfo.data.data;
                    console.log('res',pay)
                    wx.ready(function() {
                        wx.chooseWXPay({
                            timestamp: pay.timeStamp,
                            nonceStr: pay.nonceStr,
                            package: pay.package,
                            signType: pay.signType,
                            paySign: pay.paySign, // 支付签名
                            success: function(res) {
                                let arr_from_ = ['pdgzhcm','pdwzcm','hygzhcm','hywzcm','qmwzcm','cm2']
                                if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
                                    router.replace({
                                        name: "paysuccess",
                                        params: { id:orderid, type:2 }
                                    }).catch(() => {});
                                }else{
                                    router.replace({
                                        name: "testresult",
                                        params: { id:orderid, type:2 }
                                    }).catch(() => {});
                                }
                            }
                        });
                    });


                }else{
                    location.href = `https://api.zhiyileiju.cn/api/v1/payment/${payway}/${orderid}`;
                }
            } catch (err) {}
        },
        async getprice() {
            let price = await axios.get("/getSalesPrice");
            let from = this.$route.query.from || 10;
            this.price = price.data.data.filter((item) => item.fromk == from)[0];
            // console.log('this.price',this.price)
        },
        async getorderinfo(id) {
            let data = await axios.get("/order/" + id);
            this.orderinfo = data.data.data;
            if (
                this.orderinfo.order_status == "已支付" ||
                this.orderinfo.order_status == "已完成"
            ) {
                Dialog.confirm({
                    message: "当前订单已支付",
                    showCancelButton: false,
                }).then(() => {
                    router.replace({ name: "paysuccess", params: { id, type: 1 } });
                });
            } else if (this.orderinfo.order_status == "已关闭") {
                Dialog.confirm({
                    message: "当前订单已关闭",
                    showCancelButton: false,
                }).then(() => {
                    router.replace({ name: "Index" }).catch(() => {}); // on confirm
                });
            }
            let birth = data.data.data.pub_birth_date ?
                data.data.data.pub_birth_date :
                data.data.data.pub_prebirth_date;
            let fdate = birth.split(" ");
            this.orderinfo.bDate = [...fdate[0].split("-"), ...fdate[1].split(":")];
            this.orderinfo.nongli = solarLunar.solar2lunar(
                this.orderinfo.bDate[0],
                this.orderinfo.bDate[1],
                this.orderinfo.bDate[2]
            );
            console.log(this.orderinfo.nongli)
            let nowtime = Date.now();
            let cDate = data.data.data.created_at.replace(" ", "T");
            let createtime = new Date(cDate).getTime();
            this.time = 10 * 60 * 1000 - nowtime + createtime;
        },
        checkorder(id, type) {
            this.timer = setInterval(async () => {
                try {
                    let data = await axios.post("/payment/query", {
                        paytype: type,
                        order_id: id,
                    });
                    if (data.data.data.is_pay) {
                        sessionStorage.setItem('paysuccess','1');
                        router.replace({
                            name: "paysuccess",
                            params: { id, type: data.data.data.type },
                        }).catch(() => {});
                    }
                } catch (err) {}
            }, 5000);
        },
        async mcheckorder() {
                let data = await axios.post("/payment/query", {
                    paytype: this.type,
                    order_id: this.orderid,
                });
                if (data.data.data.is_pay) {
                    sessionStorage.setItem('paysuccess','1');
                    router.replace({
                        name: "paysuccess",
                        params: { id: this.orderid, type: data.data.data.paytype },
                    }).catch(() => {});
                } else {
                    // this.getpayurl('','')
                // Toast.fail("当前订单未支付");
                }
                   
           
           
        },
        getpayurls () { //弹框立即付款
        report("v1_pay_immediate_payment");
            this.getpayurl('','')
        },
        async Dcheckorder() {
            report("v1_pay_non");
            try {
                this.mcheckorder();
                let data = await axios.post("/payment/query", {
                    paytype: this.type,
                    order_id: this.orderid,
                });
                if (data.data.data.is_pay) {
                    sessionStorage.setItem('paysuccess','1');
                    router.replace({
                        name: "paysuccess",
                        params: { id: this.orderid, type: data.data.data.paytype },
                    }).catch(() => {});
                } else {
                    this.getpayurl('','')
                // Toast.fail("当前订单未支付");
                }

            } catch (err) {
                this.getpayurl('','')
                // Toast.fail("当前订单未支付");
            }
        },
        async confirmOrder(confirm_order_data_) {
            try {
                if ( window.localStorage.getItem('qm_red_packet_type') === null) {
                    let aa = await axios.post("/order/confirm", {
                        order_id: confirm_order_data_.order_id,
                        paytype: confirm_order_data_.paytype,
                    });
                }else{
                    let aa = await axios.post("/order/confirm", {
                        order_id: confirm_order_data_.order_id,
                        paytype: 2,
                        discount_id: 1
                    });
                }
                
            } catch (err) {}
        },
        bottombtnclick() {
            window.pageYOffset = 0;
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
        },
        handleScroll() {
            var scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            if (scrollTop > 300) {
                this.fixedbtn = true;
            } else {
                this.fixedbtn = false;
            }
        },
    },
};
</script>
<style scoped>
/* .wrapper {
    width: 100vw;
    height: 100vh;
    max-width: 640px;
    margin: 0 auto;
}

.block {
    width: 80%;
    background-color: white;
    border-radius: 5px;
}

.Dcheckorder {
    width: 85%;
    margin: 10px auto;
    animation: scaleDrew 1.5s ease-in-out infinite;
    font-size: 18px;
}

.block .title {
    line-height: 44px;
    text-align: center;
    font-size: 15px;
    border-bottom: 1px solid #eeeeee;
}

.block .payed {
    color: #b91e1e;
    font-size: 18px;
    line-height: 80px;
    text-align: center;
    border-bottom: 1px solid #eeeeee;
}

.unpay {
    color: #999;
    font-size: 18px;
    text-align: center;
    line-height: 60px;
} */

.wrapper {
    width: 100vw;
    height: 100vh;
    max-width: 640px;
    margin: 0 auto;
}

.block {
    width: 70%;
    /* height: 70vh; */
    background-color: white;
    border-radius: 16px;
    padding-bottom: .6rem;
}
.block .title {
    line-height: 44px;
    text-align: center;
    font-size: 15px;
    margin-top: 22px;
    margin-bottom: 17px;
}
.block .title p{
    color: #CF031A;
    /* line-height: 44px; */
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    margin: 0;
}
.bg_box_chunk_{
    overflow: hidden;
}
/* .bg_box_chunk_ */
img{ 
    width: 100%;
}

.countbox{
		margin-top:4%;
	}

	.colon {
        display: inline-block;
        margin: 0 4px;
        color: #d12229;
      }
      .block_ {
        display: inline-block;
        width: 20px;
		height: 20px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        background-color: #d12229;
      }
        



.Dcheckorder {
    width: 85%;
    margin: 10px auto;
    animation: scaleDrew 1.5s ease-in-out infinite;
    font-size: 18px;
}



.block .payed {
    width: 82%;
    margin: 0 auto;
    margin-top: 3%;
}

.unpay {
    color: #999;
    font-size: 18px;
    text-align: center;
    line-height: 60px;
}





.dialogbox {
    padding-bottom: 25px;
}

.dialogheader {
    height: 70px;
    background: #d6d5d5;

    font-size: 18pxpx;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #525252;
}

.closeicon {
    width: 30px;
    position: absolute;
    right: 18px;
}

.content {
    margin-top: 15px;
    font-size: 14px;
    font-weight: 400;
    color: #525252;
}
</style>
<style scoped>
.webfooter {
    /* height: 120px; */
    /* background: url("http://cdn1.zhiyileiju.cn/kexin.png") no-repeat top;
  background-size: 250px;
  background-color: #fff; */

    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: white;
    line-height: 2em;
    font-size: 14px;
}

.dialogrealprice {
    text-decoration: line-through;
    font-size: 12px;
    color: #979797;
    margin-right: 5px;
}

.pricewarp {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.tehuiprice {
    color: #fd0101;
    font-size: 14px;
    font-weight: Regular;
    margin-right: 5px;
}

.dialogdisp {
    font-size: 28px;
    font-weight: bold;
    font-family: Microsoft YaHei;
    color: #fd0101;
    line-height: 1em;
}

.red {
    color: #fd0101;
}

.green {
    color: #19aa0f;
}

.paybtnbox {
    width: 80vw;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.isFixed {
    position: fixed;
    font-size: 18px;
    height: 60px;
    bottom: 0;
    z-index: 999;
    max-width: 640px;
    animation: scaleDrew 1.5s ease-in-out infinite;
}
</style>
<style scoped>
.header {
    width: 100vw;
    background: #b95353;
    height: 31px;
    font-size: 18px;
    font-weight: bold;
    color: white;
}

.topheader {
    min-height: 190px;
}

.paybox {
    padding: 14px 20px;
}

.paybox .usertip {
    text-align: left;
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #020202;
}

.paybox .info {
    padding-bottom: 14px;
    border-bottom: 1px dotted #979797;
}

.userinforow {
    font-size: 14px;
    font-weight: 400;
    color: #020202;
    margin-top: 10px;
}

.paytip {
    font-size: 12px;
    font-weight: 400;
    color: #b95353;
}

.paytip.h1 {
    font-size: 26px;
    margin-top: 14px;
    margin-bottom: 14px;
    font-weight: bold;
    color: #fd0101;
}

.priceinfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 76vw;
    height: 116px;
    border: 1px solid #aaa79b;
    background: #f2eedf;
}

.paytime {
    display: flex;
    margin-top: 8px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    color: #fd0101;
    align-self: flex-start;
}

.van-count-down {
    color: #fd0101;
}

.realpricebox {
    position: relative;
}

.realprice {
    font-size: 36px;
    font-weight: bold;
    font-family: Microsoft YaHei;
    color: #fd0101;
    line-height: 1em;
}

.discountprice {
    font-size: 12px;
    font-weight: 400;
    color: #979797;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateX(100%);
    text-decoration: line-through;
}

.pricefooter {
    width: 100%;
    height: 32px;
    background: white;
    font-size: 12px;
    font-weight: 400;
    color: #020202;
}

.img {
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 15px;
    margin-top: 15px;
}

.intip {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #020202;
}

.van-icon img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.tiph2 {
    font-size: 18px;
    font-weight: bold;
    color: #fd0101;
}
</style>
<style scoped>
.infoImg {
    position: relative;
    display: block;
    background: url("http://cdn1.zhiyileiju.cn/z_wap_12.jpeg") 0 0 no-repeat;
    background-size: 100% 100%;
}

.infoText {
    padding: 10px;
    font-size: 14px;
    line-height: 20px;
}

.infoText p {
    margin: 5px 0;
}

.shadow {
    box-shadow: 0 0 8px #ddd;
    background-color: white;
    margin-bottom: 12px;
    border-radius: 6px;
}

.infoImg .info {
    width: 80%;
    margin: 0 auto 10px auto;
    padding: 12% 0;
}

.mt-12 {
    margin-top: 16px;
}

.getbutton {
    border-radius: 6px;
    margin-top: 10px;

    background: #c94b4b;
}
</style>
<style scoped>
.userCard {
    padding: 14px 19px;
}

.userBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.xingshi {
    width: 60px;
    height: 60px;
    background: url("http://cdn1.zhiyileiju.cn/NewNameBjk.png") 0 0 no-repeat;
    background-size: 100% 100%;
    line-height: 60px;
    text-align: center;
    font-size: 30px;
    margin-right: 10px;
    border: 1px solid #ddd;
    color: #b95353;
}

.userBox p {
    color: #666;
    margin: 7px;
    font-size: 14px;
    font-weight: bold;
    color: #525252;
}

.table {
    width: 100%;
    border: 1px solid #ddd;
    margin: 20px 0;
    font-size: 14px;
}

.table td,
.table th {
    text-align: center;
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid #eddbcd;
    border-right: 1px solid #eddbcd;
}

.table th,
.table td:first-child {
    background: #f2eedf;
}

.table th:first-child {
    background: #fff;
}

.table td:last-child,
.table th:last-child {
    border-right: 0;
}

.pifen {
    width: 90%;
    padding: 0 10px;
    padding-right: 5px;
}

.pifen .mc {
    width: 20px;
    height: 20px;
}

.back_jin {
    background: #fe0;
}

.back_mu {
    background: #0aa90a;
}

.back_shui {
    background: #b5ddec;
}

.back_huo {
    background: #ef3e33;
}

.back_tu {
    background: #92551c;
}

.pifen .progress {
    width: 60%;
    top: 0;
    margin: 0 5px;
}

.pifen .progress span {
    animation: animate-positive 2s;
    transition: width 0.6s ease;
}

.pifen span.fs {
    /* margin: 0 5px; */
    color: #c33936;
    font-size: 14px;
}

.progress,
.progress span {
    width: 100%;
    height: 12px;
    border-radius: 12px;
    display: inline-block;
    text-align: left;
}

.progress {
    background: #f1eae6;
    position: relative;
}

.progress span {
    position: absolute;
}

.disflex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title2 {
    font-size: 18px;
    font-weight: bold;
    color: #b95353;
    justify-content: center;
    margin-bottom: 14px;
}
</style>